// composables/useMeta.js
export const useMeta = (options) => {
  const author = "Brainz Disruptive";
  const website = "https://artvr.cz";
  const twitter = "@artvr";
  const keywords = "Art VR, virtual reality films, festival";
  const image = "https://artvr.cz/images/og_artvr.webm";

  useHead({
    title: options.title,
    meta: [
      // SEO Meta Tags
      {
        name: "description",
        content: options.description,
      },
      {
        name: "keywords",
        content: options.keywords || keywords,
      },
      {
        name: "author",
        content: options.author || author,
      },
      // Open Graph Meta Tags
      {
        property: "og:title",
        content: options.title,
      },
      {
        property: "og:description",
        content: options.description,
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: options.website || website,
      },
      {
        property: "og:image",
        content: options.image || image,
      },
      // Twitter Card Meta Tags
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        name: "twitter:title",
        content: options.title,
      },
      {
        name: "twitter:description",
        content: options.description,
      },
      {
        name: "twitter:image",
        content: options.image || image,
      },
      {
        name: "twitter:site",
        content: options.twitter || twitter,
      },
      // favicon
      {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: "/apple-touch-icon.png",
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: "/favicon-32x32.png",
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: "/favicon-16x16.png",
      },
      { rel: "manifest", href: "/site.webmanifest" },
      { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#5bbad5" },
    ],
  });
};
